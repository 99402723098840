import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public LanguageCode = new BehaviorSubject<string>('de');

  private _translocoService = inject(TranslocoService);
  private _router = inject(Router);

  constructor() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this._router.url.split('/')[1] === 'de' || this._router.url.split('/')[1] === 'en') {
          this.setLanguageCode = this._router.url.split('/')[1];
        } else {
          this.setLanguageCode = 'de';
        }
      }
    });
  }

  get getLanguageCode() {
    return this.LanguageCode.value;
  }

  set setLanguageCode(code: string) {
    this.LanguageCode.next(code);
    this._translocoService.setActiveLang(code);
  }

  isLanguageSelected(languageCode: string) {
    return this.LanguageCode.value === languageCode;
  }

  changeLanguage(languageCode: string) {
    this.setLanguageCode = languageCode;
    this._router.navigate([`/${languageCode}/${this._router.url.split('/').slice(2).join('/')}`]);
  }
}
